import { CountUpAnimation } from "../count-up-animation";

import { FactsProps } from "../../constants/types";

export const Facts = ({ facts }: FactsProps) => {
  const factsContainer = facts.map((fact) => (
    <div className="flex-fill" key={fact.unit + fact.subline}>
      <p className="h3 text-primary">
        <CountUpAnimation>{fact.number}</CountUpAnimation>
        {fact.unit}
      </p>
      <p>{fact.subline}</p>
    </div>
  ));
  return (
    <div className="facts d-flex text-center flex-wrap">{factsContainer}</div>
  );
};
